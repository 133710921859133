@use 'frontend/styles/variables' as *;

$font-family: 'Inter', 'Arial', sans-serif;

@use 'vuetify/settings' with (
  $body-font-family: $font-family,

  $typography: (
    'h1': (
      'size': 96px,
      'weight': 300,
      'line-height': 1.2em,
      'letter-spacing': -0.015em,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'h2': (
      'size': 60px,
      'weight': 300,
      'line-height': 1.2em,
      'letter-spacing': -0.005em,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'h3': (
      'size': 40px,
      'weight': 400,
      'line-height': 1.3em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'h4': (
      'size': 34px,
      'weight': 400,
      'line-height': 1.3em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'h5': (
      'size': 24px,
      'weight': 400,
      'line-height': 1.35em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'h6': (
      'size': 20px,
      'weight': 500,
      'line-height': 1.3em,
      'letter-spacing': 0.0075em,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'link-1': (
      'size': 16px,
      'weight': 400,
      'line-height': 1.25em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'link-2': (
      'size': 14px,
      'weight': 400,
      'line-height': 1.4em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'subtitle-1': (
      'size': 16px,
      'weight': 500,
      'line-height': 1.35em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'subtitle-2': (
      'size': 14px,
      'weight': 500,
      'line-height': 1.4em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'subtitle-3': (
      'size': 12px,
      'weight': 500,
      'line-height': 1.25em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'body-1': (
      'size': 16px,
      'weight': 400,
      'line-height': 1.25em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'body-2': (
      'size': 14px,
      'weight': 400,
      'line-height': 1.4em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'button': (
      'size': 16px,
      'weight': 500,
      'line-height': 1.35em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'button-small': (
      'size': 14px,
      'weight': 500,
      'line-height': 1.4em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'caption': (
      'size': 12px,
      'weight': 400,
      'line-height': 1.36em,
      'letter-spacing': 0,
      'font-family': $font-family,
      'text-transform': none,
    ),
    'overline': (
      'size': 10px,
      'weight': 400,
      'line-height': 1.56em,
      'letter-spacing': 0.015em,
      'font-family': $font-family,
      'text-transform': uppercase,
    ),
  ),
  $rounded: (
    'sm': $border-radius-sm,
    null: $border-radius-md,
    'lg': $border-radius-lg,
    'xl': $border-radius-xl,
  ),
  $shadow-key-umbra: (
    1: 0 1px 3px 0 rgba(0, 0, 0, 0.12),
  ),
  $shadow-key-penumbra: (
    1: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
  ),
  $shadow-key-ambient: (
    1: 0 1px 3px -2px rgba(0, 0, 0, 0.2),
  ),
  $button-height: 40px,
);
