@font-face {
  font-display: swap;
  font-family: Inter;
  src: url('/static/_v1/pd/fonts/Inter/Inter-Regular.woff2') format('woff2'),
    url('/static/_v1/pd/fonts/Inter/Inter-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: Inter;
  src: url('/static/_v1/pd/fonts/Inter/Inter-Medium.woff2') format('woff2'),
    url('/static/_v1/pd/fonts/Inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
