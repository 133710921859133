// Цвета UI Kit
// Текст, основные
$ui-kit-text: #111111;
$ui-kit-text-secondary: #424B58;
$ui-kit-text-info: #607A93;

// Текст, дополнительные
$ui-kit-text-success: #00815A;
$ui-kit-text-error: #F52A14;
$ui-kit-text-warning: #DF5000;
$ui-kit-disabled: #8DA2BA;

// Иконки
$ui-kit-icon-primary: #111111;
$ui-kit-icon-secondary: #607A93;

// Цвета бренда
$ui-kit-brand: #3981F1;
$ui-kit-secondary: #FF4A37;
$ui-kit-special: #904FE2;

// Цвета состояний + вспомогательные
$ui-kit-primary: #1A5DD0;
$ui-kit-success: #1EB37C;
$ui-kit-error: #FF4A37;
$ui-kit-warning: #FFA928;

// Фоны + вспомогательные цвета
$ui-kit-bg-primary: #ECF1FB;
$ui-kit-bg-secondary: #F6F9FF;
$ui-kit-bg-special: #F0E4F5;
$ui-kit-bg-success: #E0F6EE;
$ui-kit-bg-error: #FCE8E8;
$ui-kit-bg-warning: #FFF2D9;
$ui-kit-bg-club: #FFEEE5;
$ui-kit-button-text-snackbars: #76CBFF;
$ui-kit-club-gradient: radial-gradient(102.46% 204.23% at 12.61% 9.84%, #FFA553 0%, #FF4A37 100%);
$ui-kit-rating: #FFA928;
$ui-kit-bg-gray-0: #FFFFFF;
$ui-kit-bg-gray-40: #F3F6FA;
$ui-kit-bg-gray-60: #C7D1DF;
$ui-kit-bg-gray-80: #8DA2BA;

// Клубный градиент
$club-coral-linear-gradient: linear-gradient(45deg, #FF9966, #FF5E62);
$club-coral-double-radial-gradient: radial-gradient(74.2% 60.31% at 98.26% 3.82%, #FF9966 0%, rgba(255, 94, 98, 0) 100%),
  radial-gradient(122.89% 100% at 0% 100%, #FF9966 19.64%, #FF5E62 100%);

// border-radius
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 12px;
$border-radius-xl: 16px;
